<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">
              {{ $t('MENU.STORE.SALE_MAIN_ASSETS') }}
            </h3>
          </div>
          <div class="card-button">
            <v-btn
              to="/mainasset/sell/create"
              color="primary"
              small
              v-bind="attrs"
              v-on="on"
            >
              {{ $t('MENU.STORE.SALE_MAIN_ASSETS') }} +
            </v-btn>
          </div>
        </div>

        <div class="card-body">
          <v-data-table
            :headers="headers"
            :items="list"
            :loading="isLoading"
            loading-text="Malumot yuklanmoqda..."
            hide-default-footer
            no-data-text="Malumot kiritilmagan"
            class="elevation-1"
          >
            <template v-slot:[`item.action`]="{ item }">
              <allActReconAction :index="item"></allActReconAction> </template
          ></v-data-table>
          <!-- <div class="text-left my-5">
            <v-pagination
              v-model="currentPage"
              :total-visible="10"
              @input="getPostData(currentPage)"
              :length="Math.ceil(getCount / 10)"
            ></v-pagination>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import allActReconAction from '@/view/pages/clients/invoice/allActReconAction.vue'

export default {
  data() {
    return {
      currentPage: 1
    }
  },
  created() {
    if (this.$route.query.page !== undefined) {
      this.getPostData(parseInt(this.$route.query.page, 10))
      this.currentPage = parseInt(this.$route.query.page, 10)
    } else {
      this.getPostData(this.currentPage)
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('TABLE_HEADER.CLIENT'),
          value: 'seller_mainaccountant_static'
        },
        {
          text: this.$t('TABLE_HEADER.NUMBER'),
          value: 'reg_number'
        },
        {
          text: this.$t('TABLE_HEADER.CONTRACT_NUMBER'),
          value: 'buyer_contract'
        },
        {
          text: 'Yaratilgan sana',
          value: 'oper_date'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ]
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    // getCount() {
    //   return this.$store.state.requests.allActRecon.count;
    // },
    list() {
      const data = this.$store.state.requests.mainAssetRealizationFacturas
      console.log(data)
      return data
    }
  },
  methods: {
    getPostData(value) {
      if (parseInt(this.$router.currentRoute.query.page, 10) !== value) {
        this.$router.push({
          path: this.$router.currentRoute.path,
          query: { page: value }
        })
      }
      // this.$store.dispatch("getMainAssetRealizationFacturas", value);
      this.$store.dispatch('getMainAssetRealizationFacturas')
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.STORE.TITLE') },
      { title: this.$t('MENU.STORE.SALE_MAIN_ASSETS') }
    ])
  },
  components: {
    allActReconAction
  }
}
</script>

<style scoped>
.v-data-table /deep/ .v-data-table__wrapper tbody tr.v-data-table__selected {
  background-color: #efefef !important;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}
.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
}
</style>
